import { gql } from '@apollo/client';

export const DXG_SCORING_SUMMARY = gql`
  query ($id: ID!) {
    dxgScoringSummary(id: $id) {
      countRaw100
      countRawOver99
      countRawOver98
      countRawOver95
      countRawOver90
      countRawOver85
      countRawOver80

      countTotal100
      countTotalOver99
      countTotalOver98
      countTotalOver95
      countTotalOver90
      countTotalOver85
      countTotalOver80

      countChart500
      countChartOver499
      countChartOver498
      countChartOver497
      countChartOver495
      countChartOver490

      lastScoringDate
      countDxgScoring
    }
  }
`;
