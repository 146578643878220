import { useState } from 'react';

export const useFilter = () => {
  const [filterMethod, setFilterMethod] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const resetFilterMethod = () => {
    setFilterMethod(null)
    setFilterValue(null);
  };

  const handleFilterChange = (newFilter, filterBy) => {
    setFilterMethod(filterBy);
    setFilterValue(newFilter);
    console.log("Clicked: ", newFilter);
  }

  return {
    filterMethod,
    filterValue,
    handleFilterChange,
    resetFilterMethod,
  };
};
