import { gql } from '@apollo/client';

export const DXGSCORINGS_QUERY = gql`
  query ($per: String!, $page: String!,
         $sortBy: String!, $sortOrder: String!,
         $filterMethod: String, $filterValue: String) {
    dxgScorings(per: $per, page: $page,
                sortBy: $sortBy, sortOrder: $sortOrder
                filterMethod: $filterMethod, filterValue: $filterValue) {
      scoringDateTime
      requestNo
      lastPerformKey
      contentsName
      artistName
      
      totalPoint
      rawPoint
      radarChartTotal
      radarChartPitch
      radarChartStability
      radarChartExpressive
      radarChartVibratoLongtone
      radarChartRhythm
      intonation
      kobushiCount
      shakuriCount
      fallCount
      vibratoSkill
      longtoneSkill
      vibratoType
      vibratoTotalSecond
      singingRangeHighest
      singingRangeLowest
    }
    dxgScoringsCount(filterMethod: $filterMethod, filterValue: $filterValue)
  }
`;
