import React from 'react';
import styles from './Pagination.module.css';

export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = [];
  // 1ページ目
  pageNumbers.push(1);
  // ...
  if (currentPage > 1 + 2 ) {
    pageNumbers.push('...');
  }
  // currentPage - 1
  if (currentPage >= 1 + 2 ) { 
    pageNumbers.push(currentPage - 1);
  }
  // currentPage
  if (currentPage !== 1 && currentPage !== totalPages) {
    pageNumbers.push(currentPage);
  }
  // currentPage + 1
  if (currentPage <= totalPages - 2) {
    pageNumbers.push(currentPage + 1);
  }
  // ...
  if (currentPage < totalPages - 2) {
    pageNumbers.push('...');
  }
  // totalPages
  if (totalPages >= 1) {
    pageNumbers.push(totalPages);
  }

  return (
    <div className={styles.pagination}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        前へ
      </button>

      {pageNumbers.map((page, index) =>
        page === '...' ? (
          <span key={index}>...</span>
        ) : (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            disabled={currentPage === page}
          >
            {page}
          </button>
        )
      )}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages || totalPages === 0}
      >
        次へ
      </button>
    </div>
  );
};

export const PerPageSelector = ({ currentPer, onPerChange }) => {
  return ( 
    <div className={styles.perPageSelector}>
      <label>表示件数：</label>
      <select value={currentPer} onChange={(e) => onPerChange(e.target.value)}>
        <option value="500">500件</option>
        <option value="300">300件</option>
        <option value="100">100件</option>
        <option value="50">50件</option>
        <option value="20">20件</option>
      </select>
    </div>
  );
}
