import { gql } from '@apollo/client';

export const SEARCH_DXGSCORINGS_QUERY = gql`
  query ($searchValue: String,
         $page: String!, $per: String!
         $sortBy: String!, $sortOrder: String! ) {
    searchSongs (searchValue: $searchValue,
                 sortBy: $sortBy, sortOrder: $sortOrder,
                 page: $page, per: $per){
      scoringDateTime
      requestNo
      contentsName
      artistName
      artistId
      totalPoint
      rawPoint

      lastPerformKey

      scoreFlag
      radarChartTotal
      radarChartPitch
      radarChartStability
      radarChartExpressive
      radarChartVibratoLongtone
      radarChartRhythm
      intonation
      kobushiCount
      shakuriCount
      fallCount
      vibratoSkill
      longtoneSkill
      vibratoTotalSecond
      singingRangeHighest
      singingRangeLowest
    }
    searchSongsCount(searchValue: $searchValue)
  }
`;
