import React, { useContext } from "react";
import styles from "./index.module.css";
import { AuthContext } from "../../App";
import { DxgScoringSummary } from "./DxgScoringSummary";

export const Home = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.userInfoBox}>
          <h4>ユーザ名: {currentUser?.userName}</h4>
          <h4>メールアドレス: {currentUser?.email}</h4>
        </div>
      </div>
      <div className={styles.center}>
        <DxgScoringSummary id={currentUser?.id} />
      </div>
      <div className={styles.right}></div>
    </div>
  );
};
