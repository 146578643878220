import Cookies from "js-cookie";
import client from "api/client";

export const reserveSong = async (reqNo) => {
  const QRCodeValue = Cookies.get("QRCode");

  try {
    const response = await client.post(
      'api/v1/dam/reserve_song', {
        qrCode: QRCodeValue,
        reqNo: reqNo,
      },
      {
        headers: {
          "access-token": Cookies.get("_access_token"),
          client: Cookies.get("_client"),
          uid: Cookies.get("_uid"),
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      alert(response.data.message);
    } else {
      console.error('Failed to send QR code data to Rails.');
    }
  }
  catch (error) {
    const res = error.response.data;
    console.log('エラーコード: ', res.errorCode);
    console.log(res.message);
    alert('エラーコード: ' + res.errorCode + "\n" + res.message)
  }
};