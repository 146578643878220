import { useState } from 'react';

export const useDamSearch = () => {
  const [damSearchValue, setDamSearchValue] = useState('');

  const handleSearchButtonClick = (event) => {
    event.preventDefault();
    const newDamSearchValue = event.target.querySelector('input').value
    setDamSearchValue(newDamSearchValue);
    console.log('検索値:', newDamSearchValue);
  };

  return {
    damSearchValue,
    handleSearchButtonClick
  };
};