import { useState } from 'react';

export const usePagination = (initialPage, initialPer) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPer, setCurrentPer] = useState(initialPer);

  const resetPagination = () => {
    setCurrentPage(initialPage);
    // setCurrentPer(initialPer);
  };

  const handlePageChange = (newPage, maxPage) => {
    if (newPage > 0 && newPage <= maxPage) {
      setCurrentPage(newPage);
    }
  };

  const handlePerChange = (newPer) => {
    const newPage = Math.ceil(((currentPage - 1) * currentPer + 1) / newPer);
    setCurrentPage(newPage);
    setCurrentPer(newPer);
  };

  return {
    currentPage,
    currentPer,
    handlePageChange,
    handlePerChange,
    resetPagination,
  };
};
