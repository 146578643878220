import { useState } from 'react';

export const useSorting = () => {
  const [currentSortBy, setCurrentSortBy] = useState("scoring_date_time");
  const [currentSortOrder, setCurrentSortOrder] = useState("DESC");

  const handleSortChange = (field) => {
    console.log("headerClicked:", field);
    if (currentSortBy === field) {
      setCurrentSortOrder(currentSortOrder === 'DESC' ? 'ASC' : 'DESC');
      console.log("order:", currentSortOrder === 'DESC' ? 'ASC' : 'DESC');
    } else {
      setCurrentSortBy(field);
      setCurrentSortOrder('DESC');
      console.log("order:", 'DESC');
    }
  };

  return {
    currentSortBy,
    currentSortOrder,
    handleSortChange,
  };
}