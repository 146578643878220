import React, { useContext, useState, useEffect } from 'react';
import client from "api/client";
import Cookies from "js-cookie";

import { AuthContext } from "App";

export const UserInfo = () => {
  const EditDataPage = () => {
    const [cdmNo, setCdmNo] = useState('');
    const [encryptedDamtomoId, setEncryptedDamtomoId] = useState('');
    const [shukeiId, setShukeiId] = useState('');
  
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
      if (currentUser) {
        setCdmNo(currentUser.cdmNo || '');
        setEncryptedDamtomoId(currentUser.encryptedDamtomoId || '');
        setShukeiId(currentUser.shukeiId || '');
      }
    }, [currentUser]);

    const handleFormSubmit = async (event) => {
      event.preventDefault();
  
      try {
        const response = await client.patch(
          "/api/v1/users/update", {
            user: {
              cdm_no: cdmNo,
              encrypted_damtomo_id: encryptedDamtomoId,
              shukei_id: shukeiId,
            },
          },
          {
            headers: {
              "access-token": Cookies.get("_access_token"),
              client: Cookies.get("_client"),
              uid: Cookies.get("_uid"),
              'Content-Type': 'application/json',
            },
          },
        );
        console.log(response);
        if (response.status === 200) {
          alert(response.data.message);
        } else {
          console.error('Failed to send QR code data to Rails.');
        }
      }
      catch (error) {
        console.log(error);
        alert('更新中に問題が発生しました。' + error.response.data.errors);
      }
    }
  
    return (
      <div>
        <h1>設定</h1>
        <form onSubmit={handleFormSubmit}>
          <label>
            cdm番号:
            <input
              type="text"
              value={cdmNo}
              onChange={(e) => setCdmNo(e.target.value)}
            />
          </label>
          <br />
          <label>
            暗号化されたダムともid:
            <input
              type="text"
              value={encryptedDamtomoId}
              onChange={(e) => setEncryptedDamtomoId(e.target.value)}
            />
          </label>
          <br />
          <label>
            精密集計id:
            <input
              type="text"
              value={shukeiId}
              onChange={(e) => setShukeiId(e.target.value)}
            />
          </label>
          <br />
          <button type="submit">変更する</button>
        </form>
      </div>
    );
  };
  return <EditDataPage />
}
