import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import { ApolloProvider } from '@apollo/client/react';
import client from 'apollo-client'; // Apollo Clientの設定ファイル

import 'index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}> {/* ApolloProviderを使用 */}
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </ApolloProvider>
);
