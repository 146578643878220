import React, { useState } from 'react';
import Cookies from "js-cookie";
import Modal from 'react-modal';
import client from "api/client";
import { QrReader } from 'react-qr-reader';

Modal.setAppElement('#root'); // モーダルがbodyの外にレンダリングされるのを防ぐ

export const QRCodeModal = ({ isOpen, onClose }) => {
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const closeModal = () => {
    setResult(null);
    onClose();
  };

  const sendQRCode = async (data) => {
    try {
      const response = await client.post(
        'api/v1/dam/connect', { qrCode: data, },
        {
          headers: {
            "access-token": Cookies.get("_access_token"),
            client: Cookies.get("_client"),
            uid: Cookies.get("_uid"),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setMessage(response.data.message);
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 2 * 60 * 60 * 1000);
        document.cookie = `QRCode=${encodeURIComponent(response.data.qrCodeValue)}; expires=${expirationDate.toUTCString()}; path=/`;
      } else {
        setMessage('Failed to send QR code data to Rails.')
        console.error('Failed to send QR code data to Rails.');
      }
    } 
    catch (error) {
      const res = error.response.data;
      console.log(res);
      console.log('エラーコード: ', res.errorCode);
      console.log(res.message);

      setErrorCode(res.errorCode);
      setMessage(res.message);
      // console.error('Error while sending QR code data:', error);
    }
  };

  const handleQrCodeResult = (result, error) => {
    if (!!result) {
      sendQRCode(result?.text);
      setResult(result?.text);
    }
    if (!!error) {
      // console.info(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="QR Code Modal"
    >
      {result ? (
        <div>
          <p>エラーコード: {errorCode}</p>
          <p>{message}</p>
          <p>Data: {result}</p>
          <button onClick={() => {
            
            closeModal();
          }}>Close</button>
        </div>
      ) : (
        <QrReader
          style={{ width: '100%' }}
          onResult={handleQrCodeResult}
        />
      )}
    </Modal>
  );
};
