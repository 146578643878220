import React from "react";
import Cookies from "js-cookie";

import { convertToDateFormat, convertToScoreFormat, convertToKeyFormat, calculateRowColorClass } from "utils";
import { reserveSong } from "api/dam/reserveSong";

import { midiToRangeMap } from "consts/ScoringConstMap";

import styles from './Table.module.css';

export const Table = ({
  scores,
  currentPage,
  currentPer,
  handleFilterChange,
  handleSortChange,
  currentSortBy,
  currentSortOrder,
}) => {
  const QRCodeValue = Cookies.get("QRCode");
  const SortableHeader = ({ label, sortBy }) => {
    const isActive = currentSortBy === sortBy;
    const labelStyle = isActive ? (currentSortOrder === 'ASC' ? styles.asc : styles.desc) : styles.none;

    return (
      <div 
        onClick={() => handleSortChange(sortBy)}
        className={`${styles.sortableHeader} ${labelStyle}`}
      >
        {label}
      </div>
    );
  };

  return (
    <table className={styles.dataTable}>
      <thead>
        <tr>
          <th className={styles.tableIndexHeader}>
            <div>No.</div>
          </th>
          <th className={styles.tableReqnoDateHeader}>
            <div>選曲番号</div>
            <div>
              <SortableHeader label="歌唱日" sortBy="scoring_date_time" />
              / Key
            </div>
          </th>
          <th className={styles.tableSongHeader}>
            <SortableHeader label="曲名" sortBy="contents_name" />
            <SortableHeader label="歌手名" sortBy="artist_name" />
          </th>
          <th className={styles.tablePointHeader}>
            <SortableHeader label="点数" sortBy="total_point" />
          </th>
          <th className={styles.tableRawPointHeader}>
            <SortableHeader label="素点" sortBy="raw_point" />
          </th>
          <th className={styles.tableChartHeader}>
            <div>
              <SortableHeader label="チ" sortBy="radar_chart_total" />
            </div>
            <div>
              <SortableHeader label="音" sortBy="radar_chart_pitch" />
            </div>
          </th>
          <th className={styles.tableChartHeader}>
            <div>
              <SortableHeader label="安" sortBy="radar_chart_stability" />
            </div>
            <div>
              <SortableHeader label="表" sortBy="radar_chart_expressive" />
            </div>
          </th>
          <th className={styles.tableChartHeader}>
            <div>
              <SortableHeader label="VL" sortBy="radar_chart_vibrato_longtone" />
            </div>
            <div>
              <SortableHeader label="リ" sortBy="radar_chart_rhythm" />
            </div>
          </th>
          <th className={styles.tableChartHeader}>
            <div>
              抑
            </div>
            <div>
              し
            </div>
          </th>
          <th className={styles.tableChartHeader}>
            <div>
              こ
            </div>
            <div>
              フ
            </div>
          </th>
          <th className={styles.tableChartLargeHeader}>
            <div>
              -
            </div>
            <div>
              ビ秒
            </div>
          </th>
          <th className={styles.tableChartLargeHeader}>
            <div>
              最高
            </div>
            <div>
              最低
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {scores?.map((score, idx) => {
          const index = (currentPage - 1) * currentPer + idx + 1;
          const rowColorClass = styles[calculateRowColorClass(score['totalPoint'], score['rawPoint'], score['scoreFlag'])];
          return (
            <tr key={index} className={rowColorClass}>
              <td>
                <div className={styles.tableIndex}>{index}</div>
              </td>
              <td>
                <div className={styles.tableReqnoDate}>
                  <div className={styles.tableRequestNoArea}>
                    <div className={styles.tableRequestNo}>
                      {QRCodeValue ? (
                        <div className={styles.linkedContent} onClick={() => reserveSong(score['requestNo'])}>
                          {score['requestNo']}
                        </div>
                      ) : (
                        score['requestNo']
                      )}
                    </div>
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableDateKeyArea}>
                    <div className={styles.tableDateKey}>
                      <div className={styles.tableDate}>
                        {convertToDateFormat(score['scoringDateTime'], '/')}
                      </div>
                      <div className={styles.tableKey}>
                        {convertToKeyFormat(score['lastPerformKey'])}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableSong}>
                  <div className={styles.tableSongNameArea}>
                    <div className={styles.tableSongName} data-full-text={score['contentsName']}>
                      {score['contentsName']}
                    </div>
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableArtistNameArea}>
                    <div className={styles.tableArtistName} data-full-text={score['artistName']}>
                      {score['artistName']}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tablePoint}>
                  {convertToScoreFormat(score['totalPoint'])}
                </div>
              </td>
              <td>
                <div className={styles.tableRawPoint}>
                  {convertToScoreFormat(score['rawPoint'])}
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {score['radarChartTotal']}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {score['radarChartPitch']}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {score['radarChartStability']}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {score['radarChartExpressive']}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {score['radarChartVibratoLongtone']}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {score['radarChartRhythm']}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {score['intonation']}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {score['shakuriCount']}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {score['kobushiCount']}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {score['fallCount']}
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    -
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {
                      score['vibratoTotalSecond'] ?
                        score['vibratoTotalSecond'] / 10
                      :
                        null
                    }
                  </div>
                </div>  
              </td>
              <td>
                <div className={styles.tableCharts}>
                  <div className={styles.tableChart}>
                    {midiToRangeMap[score['singingRangeHighest']]}
                  </div>
                  <div className={styles.separateLine}></div>
                  <div className={styles.tableChart}>
                    {midiToRangeMap[score['singingRangeLowest']]}
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
