import React, { useState, useEffect } from "react";
import styles from './SearchForm.module.css';
export const SearchForm = ({ handleSearchButtonClick }) => {
  const [inputValue, setInputValue] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    const storedHistory = localStorage.getItem("searchHistory");
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  // inputが変更された時に検索値を更新
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // フォームがサブミットされた時に検索値を保存
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const newHistory = Array.from(new Set([inputValue, ...searchHistory]));
    localStorage.setItem("searchHistory", JSON.stringify(newHistory));
    handleSearchButtonClick(e);
  };

  return (
    <form className={styles.searchForm} onSubmit={handleSearchSubmit}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="検索する..."
        list="autocomplete-options"
      />
      <datalist id="autocomplete-options">
        {searchHistory.map((item) => (
          <option key={item} value={item} />
        ))}
      </datalist>
      <button type="submit">検索</button>
    </form>
  );
};
