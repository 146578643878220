import { Link } from "react-router-dom";
import styles from './index.module.css';

export const Setting = () => {
  return (
    <div className={styles.settingContainer}>
      <h1>設定</h1>
      <ul>
        <li className={styles.listItem}>
          <div className={styles.linkTitle}>
            <Link to="userInfo">ユーザ情報設定</Link>
          </div>
          <div className={styles.description}>
            ユーザ情報が設定できます。
          </div>
        </li>
        <li className={styles.listItem}>
          <div className={styles.linkTitle}>
            <Link to="/dxgscoring/rebuild_shukei">データの再構築</Link>
          </div>
          <div className={styles.description}>
            精密集計の履歴からデータの再構築を行います。この操作には時間がかかります。
            <br />
            初めての方は1度この操作を実行してください。
          </div>
        </li>
      </ul>
    </div>
  )
}
