import React, { useContext, useEffect } from "react"
import Cookies from "js-cookie";
import { useQuery } from '@apollo/client';
import { AuthContext } from "App"
import { Pagination, PerPageSelector } from 'components/Pagination';
import { usePagination } from 'hooks/usePagination';
import { useFilter } from 'hooks/useFilter'
import { useSorting } from "hooks/useSorting";
import { Table } from './Table';

import { DXGSCORINGS_QUERY } from './queries';

import styles from './index.module.css';

export const History = () => {
  const { currentUser } = useContext(AuthContext);
  const { currentPage, currentPer, handlePageChange, handlePerChange, resetPagination } = usePagination(1, 100);
  const { filterMethod, filterValue, handleFilterChange  } = useFilter();
  const { currentSortBy, currentSortOrder, handleSortChange } = useSorting();

  useEffect(() => {
    resetPagination();
  }, [filterValue]);

  const { loading, error, data } = useQuery(
    DXGSCORINGS_QUERY,
    {
      variables: {
        page: currentPage.toString(),
        per: currentPer.toString(),
        sortBy: currentSortBy.toString(),
        sortOrder: currentSortOrder.toString(),
        filterMethod,
        filterValue,
      },
      context: {
        headers: {
          "access-token": Cookies.get("_access_token"),
          client: Cookies.get("_client"),
          uid: Cookies.get("_uid"),
        }
      },
      fetchPolicy: "network-only",
    }
  );
  if (loading) return 'Loading...';
  if (error) return `Error: ${error.message}`;

  const scoringsCount = data.dxgScoringsCount;
  const totalPages = Math.ceil(scoringsCount / currentPer);

  return (
    <div className={styles.historyContainer}>
      <h1>歌唱履歴</h1>
      <div className={styles.description}>{currentUser?.userName}さんの歌唱履歴ページです</div>
      
      <div className={styles.scoringsCount}>
        {scoringsCount}件のデータ
      </div>
      
      <div className={styles.paginationContainerHeader}>
        <div></div>
        {/* 履歴検索をできるようにしたい */}
        {/* <div className={styles.searchFormContainer}>
          <SearchForm handleSearchButtonClick={handleSearchButtonClick} />
        </div> */}
        
        <div className={styles.perPageSelectorContainer}>
          <PerPageSelector
            currentPer={currentPer}
            onPerChange={(newPer) => handlePerChange(newPer)}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table
          scores={data.dxgScorings}
          currentPage={currentPage}
          currentPer={currentPer}
          handleFilterChange={handleFilterChange}
          handleSortChange={handleSortChange}
          currentSortBy={currentSortBy}
          currentSortOrder={currentSortOrder}
        />
      </div>

      <div className={styles.paginationContainerFooter}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage, totalPages)}
        />
      </div>
    </div>
  );
}
