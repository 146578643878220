import React from 'react';
import styles from './Table.module.css';

export const Table = ({ dxgScoringSummary }) => {
  const scoreValues = [100, 99, 98, 95, 90, 85, 80];
  const chartValues = [500, 499, 498, 497, 495, 490];

  const renderTableRow = (scoreType, label, values) => (
    <tr key={scoreType}>
      <td>{label}</td>
      {values.map((value, index) => (
        <td key={value}>
          {index === 0
            ? dxgScoringSummary[`count${scoreType}${value}`]
            : dxgScoringSummary[`count${scoreType}Over${value}`]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th></th>
            {scoreValues.map((score) => (
              <th key={score}>{score}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderTableRow('Raw', '素点', scoreValues)}
          {renderTableRow('Total', '総合得点', scoreValues)}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th></th>
            {chartValues.map((chart) => (
              <th key={chart}>{chart}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderTableRow('Chart', 'チ計', chartValues)}
        </tbody>
      </table>
    </div>
  );
};
