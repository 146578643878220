export const convertToDateFormat = (input, sep="") => {
  if (typeof input !== 'string' || input.length < 8) {
    // console.error('Invalid input');
    // return null;
    return 'no data';
  }

  const year = input.substring(0, 4);
  const month = input.substring(4, 6);
  const day = input.substring(6, 8);

  const formattedDate = `${year}${sep}${month}${sep}${day}`;
  return formattedDate;
}

export const convertToScoreFormat = (input) => {
  return ( input / 1000 ).toFixed(3);
}

export const convertToKeyFormat = (input) => {
  if (input == null) {
    return '-';
  }
  if (input < 0) {
    return '♭' + -input;
  } else if (input > 0) {
    return '＃' + input;
  }
  else return input;
}

export const calculateRowColorClass = (totalPoint, rawPoint, scoreFlag) => {
  if (scoreFlag === 0) {
    return 'noScoringGame';
  }
  if (rawPoint === 100000) {
    return 'true100'
  } else if (totalPoint === 100000) {
    return 'total100';
  } else if (totalPoint >= 99000) {
    return 'totalOver99';
  } else if (totalPoint >= 98000) {
    return 'totalOver98';
  } else if (totalPoint >= 95000) {
    return 'totalOver95';
  } else if (totalPoint >= 90000) {
    return 'totalOver90';
  } else if (totalPoint >= 85000) {
    return 'totalOver85';
  } else if (totalPoint >= 80000) {
    return 'totalOver80';
  }
  return '';
};
