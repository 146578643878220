import { useQuery } from '@apollo/client';
import { convertToDateFormat } from 'utils'
import { DXG_SCORING_SUMMARY } from './query';
import { Table } from './Table';
import styles from './index.module.css';

export const DxgScoringSummary = ({id}) => {
  const { loading, error, data } = useQuery(
    DXG_SCORING_SUMMARY,
    {
      variables: { id }
    }
  );
  if (loading) return 'Loading...';
	if (error) return `Error: ${error.message}`;

  if (data.dxgScoringSummary) {
    const lastScoringDate = convertToDateFormat(data.dxgScoringSummary.lastScoringDate, '/');
    const countDxgScoring = data.dxgScoringSummary.countDxgScoring;
    return (
      <div className={styles.dxgScoringSummary}>
        <h2>DXG Scoring Summary</h2>
        <p>最終歌唱日：{lastScoringDate}</p>
        <p>採点回数：{countDxgScoring}回</p>
        <Table dxgScoringSummary={data.dxgScoringSummary} />
      </div>
    );
  } else {
    return <>精密集計からデータを取得してください</>;
  }
};
