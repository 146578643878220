import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import client from 'api/client';

export const DamtomoGetDxgScoring = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // APIからデータを取得
    client.get('/api/v1/damtomo/get_dxg_scoring', {
      headers: {
        "access-token": Cookies.get("_access_token"),
        client: Cookies.get("_client"),
        uid: Cookies.get("_uid"),
      },
    })
    .then((response) => {
      // データをstateに設定
      setData(response.data);
    })
    .catch((error) => {
      console.error('APIリクエストエラー:', error);
      setError(error.response.data.message);
    });
  }, []);

  const LoadStatus = () => {
    if(data){
      return <p>{data.message}</p>
    } else if(error){
      return <p>{error}</p>
    } else {
      return <p>データを取得中...</p>;
    }
  };
  return (
    <LoadStatus />
  )
};
