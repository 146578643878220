import React, { useEffect } from "react"
import Cookies from "js-cookie";
import { useQuery } from '@apollo/client';
import { Pagination, PerPageSelector } from 'components/Pagination';
import { usePagination } from 'hooks/usePagination';
import { useSorting } from "hooks/useSorting";
import { useDamSearch } from 'hooks/useDamSearch';

import { SEARCH_DXGSCORINGS_QUERY } from './queries';
import { Table } from './Table';
import { SearchForm } from './SearchForm'

import styles from './index.module.css';

export const Search = () => {
	const { currentPage, currentPer, handlePageChange, handlePerChange, resetPagination } = usePagination(1, 100);
	const { currentSortBy, currentSortOrder, handleSortChange } = useSorting();
	const { damSearchValue, handleSearchButtonClick } = useDamSearch();
	
	useEffect(() => {
		resetPagination();
	  }, [damSearchValue]);

	const { loading, error, data } = useQuery(
		SEARCH_DXGSCORINGS_QUERY,
		{
			variables: {
				searchValue: damSearchValue,
				page: currentPage.toString(),
				per: currentPer.toString(),
				sortBy: currentSortBy.toString(),
				sortOrder: currentSortOrder.toString(),
			},
			context: {
				headers: {
					"access-token": Cookies.get("_access_token"),
					client: Cookies.get("_client"),
					uid: Cookies.get("_uid"),
				}
			},
      fetchPolicy: 'network-only',
		}
	);

	if (loading) return 'Loading...';
	if (error) return `Error: ${error.message}`;
	const searchResultCount = data.searchSongsCount;
	const totalPages = Math.ceil(searchResultCount / currentPer);

	return (
    <div className={styles.searchContainer}>
      <h1>曲名検索</h1>
      <div className={styles.description}>曲名検索を行うことができます</div>

      <div className={styles.searchResultCount}>
        <p>{searchResultCount}件のデータ</p>
      </div>

      <div className={styles.paginationContainerHeader}>
        <div className={styles.searchFormContainer}>
          <SearchForm handleSearchButtonClick={handleSearchButtonClick} />
        </div>
        
        <div className={styles.perPageSelectorContainer}>
          <PerPageSelector
            currentPer={currentPer}
            onPerChange={(newPer) => handlePerChange(newPer)}
          />
        </div>
      </div>

      <div className={styles.searchResultContainer}>
        <Table
          scores={data.searchSongs}
          currentPage={currentPage}
          currentPer={currentPer}
          handleSortChange={handleSortChange}
          currentSortBy={currentSortBy}
          currentSortOrder={currentSortOrder}
        />
      </div>

      <div className={styles.paginationContainerFooter}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(newPage) => handlePageChange(newPage, totalPages)}
        />
      </div>
    </div>
  );
};
